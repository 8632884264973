import React from 'react';
import { MetaDataContext } from '@/context';
import { getChallengeMetaData } from '@/api/challange';
import useAuth from './useAuth';
export function useMetaData() {
    var _a = React.useContext(MetaDataContext), metaData = _a.metaData, setMetaData = _a.setMetaData;
    var isAuth = useAuth().isAuth;
    /**
     * Change local data in context
     * @param data IChallangeMetaData
     */
    var setLocalData = function (data) {
        setMetaData(data);
    };
    /**
     * Refresh local data by server data
     * @param data IChallangeMetaData
     */
    var refreshMetaData = function () {
        getChallengeMetaData({})
            .then(function (response) {
            setLocalData(response.data);
        })
            .catch(function (error) { return console.log("update meta data Error", error); });
    };
    React.useEffect(function () {
        refreshMetaData();
    }, [isAuth]);
    return { metaData: metaData, refreshMetaData: refreshMetaData };
}
