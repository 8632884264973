var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
var LoadResultContext = React.createContext({});
function LoadResultContextProvider(_a) {
    var children = _a.children;
    var _b = useState({}), data = _b[0], setData = _b[1];
    var providerInterface = React.useMemo(function () { return ({
        data: data,
        setData: setData
    }); }, [data]);
    return (_jsx(LoadResultContext.Provider, __assign({ value: providerInterface }, { children: children })));
}
export function useLoadResultContext() {
    var _a = useContext(LoadResultContext), data = _a.data, setData = _a.setData;
    var getValue = function (id) { return data[id] || {}; };
    // @ts-ignore
    var resetValue = function (id) { return setData(function (prev) {
        var _a = prev, _b = id, resetedData = _a[_b], adjData = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        return __assign({}, adjData);
    }); };
    var setValue = function (id, formData) { return setData(function (prev) {
        var _a;
        return (__assign(__assign({}, prev), (_a = {}, _a[id] = formData, _a)));
    }); };
    return { data: data, getValue: getValue, setValue: setValue, resetValue: resetValue };
}
export default LoadResultContextProvider;
