/** @format */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import client from './provider';
/**
 * Профайл участника
 *
 * https://uponblog.ru/api_list.php?page=30
 *
 * @param {object} data
 * @returns IUserData
 */
export function getUserData(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_info.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
export function setUserDataAfterReg(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_update.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Обновление профиля (только данные, без аватарки)
 *
 * https://uponblog.ru/api_list.php?page=31
 *
 * @param {IUpdateUserData} data
 * @returns
 */
export function updateUserData(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_update_data.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 *
 * Обновление профиля (только аватарка)
 *
 * https://uponblog.ru/api_list.php?page=32
 *
 * @param {IUpdateUserAvatar} data
 * @returns IUpdateUserAvatarResponse
 */
export function updateUserAvatar(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_update_profile_avatar.php';
                    return [4 /*yield*/, client.customPost(url, data, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Профиль. Статистика (побед, участий, общий выйгрыш)
 *
 * https://uponblog.ru/api_list.php?page=33
 *
 * @param data
 * @returns
 */
export function getUsersStatistics(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_stats.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Обновить профиль. Телефон
 *
 * Шаг 1. запрос на обновление телефона
 *
 * https://uponblog.ru/api_list.php?page=46
 *
 * @param {IPhoneChangeDTO} data
 * @returns IPhoneLoginResponse
 */
export function changeUserPhone(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_update_phone_by_token.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Обновить профиль. Телефон
 *
 * Шаг 2. запрос на проверку кода телефона
 *
 * https://uponblog.ru/api_list.php?page=46
 *
 * @param {IPhoneLoginCode} data
 * @returns IPhoneLoginResponse
 */
export function verificationChangeUserPhone(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_update_phone_by_token.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, response.data];
                    }
                    throw Error('Неверный код');
            }
        });
    });
}
/**
 * Пользователь. Обновление профиля. Почта.
 *
 * Шаг 1. запрос на обновление почты
 *
 * https://uponblog.ru/api_list.php?page=47
 *
 * @param {IChangeEmailDTO} data
 * @returns IPhoneLoginResponse
 */
export function changeUserEmail(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_update_email_by_token.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Обновление профиля. Почта.
 *
 * Шаг 2. запрос на проверку кода почты
 *
 * https://uponblog.ru/api_list.php?page=47
 *
 * @param {IPhoneLoginCode} data
 * @returns IPhoneLoginResponse
 */
export function verificationChangeUserEmail(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_profile_update_email_by_token.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Удаление
 *
 * https://uponblog.ru/api_list.php?page=35
 *
 * @param {object} data
 * @returns any
 */
export function deleteUserAccount(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_is_delete.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Верификация для блогеров
 *
 * Шаг 1. Пройти верификацию
 *
 * https://uponblog.ru/api_list.php?page=41
 *
 * @param {IBlogerDTO} data
 * @returns Response
 */
export function bloggerVerification(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_blogger.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Верификация для блогеров
 *
 * TODO: CHANGE JSON TO FORMDATA
 *
 * Шаг 2. закачать медиафайл - /api/user_blogger_file.php
 *
 * @param data
 * @returns
 */
export function bloggerVideoVerification(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_blogger_file.php';
                    return [4 /*yield*/, client.customPost(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Жалоба.
 *
 * https://uponblog.ru/api_list.php?page=48
 *
 *
 * @param {IClaimDTO} data
 * @returns Response
 */
export function claimUser(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_claim.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Достижения.
 *
 * https://uponblog.ru/api_list.php?page=52
 *
 * @param {object} data
 * @returns IAchievmentsResponse
 */
export function getUserAchivments(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_achievement_list.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Восстановление пароля через почту.
 *
 * Шаг 1. отправка почты
 *
 * https://uponblog.ru/api_list.php?page=34
 *
 * @param {Omit<IMailLogin, "password">} data
 * @returns IPhoneLoginResponse
 */
export function sendEmail(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_restore_access_by_email.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Восстановление пароля через почту.
 *
 * Шаг 2. отправка кода и токена
 *
 * https://uponblog.ru/api_list.php?page=34
 *
 * @param {IMailRegistrationCodeRequest} data
 * @returns IPhoneLoginResponse
 */
export function restoreEmailByCode(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_restore_access_by_email.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        localStorage.setItem(client.tokenField, response.data.token);
                        window.dispatchEvent(new Event('storage'));
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, response.data];
            }
        });
    });
}
/**
 * Восстановление пароля через почту.
 *
 * Шаг 3. отправка пароля, повтор пароля, email_code (подтвержденного) и токена
 *
 * https://uponblog.ru/api_list.php?page=34
 *
 * @param {IChangePassword} data
 * @returns IPhoneLoginResponse
 */
export function updatePassword(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_restore_access_by_email.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Покупка премиума
 *
 * https://uponblog.ru/api_list.php?page=93
 *
 * @param {object} data
 * @returns IUserData
 */
export function buyPremium() {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_premium_buy.php';
                    return [4 /*yield*/, client.customPost(url)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Отмена премиум подписки
 *
 * https://uponblog.ru/api_list.php?page=103
 *
 * @param {object} data
 * @returns IUserData
 */
export function cancelPremium() {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_premium_cancel.php';
                    return [4 /*yield*/, client.customPost(url)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Пользователь. Купить +3 продвижения.
 *
 * https://uponblog.ru/api_list.php?page=94
 *
 * @param {IBuyPromotionsRequest} data
 * @returns
 */
export function buyPromotions(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_promotion_buy.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
