var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var MyChallengeJudgeSwiperContext = React.createContext({
    isClickJudgeSwiper: "",
    setIsClickJudgeSwiper: function () { },
});
export function MyChallengeJudgeSwiperContextProvider(_a) {
    var children = _a.children;
    var _b = React.useState(""), isClickJudgeSwiper = _b[0], setIsClickJudgeSwiper = _b[1];
    var providerInterface = React.useMemo(function () { return ({
        isClickJudgeSwiper: isClickJudgeSwiper,
        setIsClickJudgeSwiper: setIsClickJudgeSwiper
    }); }, [isClickJudgeSwiper]);
    return (_jsx(MyChallengeJudgeSwiperContext.Provider, __assign({ value: providerInterface }, { children: children })));
}
