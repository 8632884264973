var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/blank.scss';
import './styles/fonts.scss';
import './styles/main.scss';
import './styles/animations.scss';
import { ContextProviders } from './context/ContextProviders';
var Main = lazy(function () { return import('./pages/main/Main'); });
var Login = lazy(function () { return import('./pages/login/Login'); });
var Terms = lazy(function () { return import('./pages/terms/Terms'); });
var Test = lazy(function () { return import('./pages/test/test'); });
var Profile = lazy(function () { return import('./pages/profile/Profile'); });
var Challenge = lazy(function () { return import('./pages/challenge/Challenge'); });
var Policy = lazy(function () { return import('./pages/policy/Policy'); });
var Agree = lazy(function () { return import('./pages/agree/Agree'); });
var MyChallenges = lazy(function () { return import('./pages/MyChallenges/MyChallenges'); });
var Challenges = lazy(function () { return import('./pages/Challenges/Challenges'); });
var SocialsIntegration = lazy(function () { return import('./pages/SocialsIntegrationPage/SocialsIntegrationPage'); });
function Router() {
    return (_jsx(Suspense, __assign({ fallback: null }, { children: _jsx(ContextProviders, { children: _jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(Main, {}) }), _jsx(Route, { path: '/error', element: _jsx(Login, {}) }), _jsx(Route, { path: '/challenges', element: _jsx(Challenges, {}) }), _jsx(Route, { path: '/challenges/:id', element: _jsx(Challenge, {}) }), _jsx(Route, { path: '/profile', element: _jsx(Profile, {}) }), _jsx(Route, { path: '/my-challenges', element: _jsx(MyChallenges, {}) }), _jsx(Route, { path: '/terms/:id', element: _jsx(Terms, {}) }), _jsx(Route, { path: '/terms', element: _jsx(Terms, {}) }), _jsx(Route, { path: '/terms/Personal_Data_Processing_and_Privacy_Policy.html', element: _jsx(Policy, {}) }), _jsx(Route, { path: '/terms/Consent_to_distribution.html', element: _jsx(Agree, {}) }), _jsx(Route, { path: '/socials-integration', element: _jsx(SocialsIntegration, {}) })] }) }) }) })));
}
export default Router;
