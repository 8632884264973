var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ProfileData } from '@/context';
import { getUserData, getUsersStatistics, setUserDataAfterReg, updateUserAvatar } from '@/api/user';
import { getBalance } from '@/api/balance';
import useAuth from './useAuth';
export function useProfileData() {
    var _this = this;
    var _a = React.useContext(ProfileData), data = _a.data, setData = _a.setData, userBalance = _a.userBalance, setUserBalance = _a.setUserBalance, userStatistic = _a.userStatistic, setUserStatistic = _a.setUserStatistic;
    var isAuth = useAuth().isAuth;
    // const [isLoading, setIsLoading] = React.useState<boolean>(true);
    /**
     * Change local data in context
     * @param userData IUserData
     */
    var setLocalData = function (userData) {
        // @ts-ignore
        var token = userData.token, profileData = __rest(userData, ["token"]);
        setData(function (prev) { return (__assign(__assign({}, prev), profileData)); });
    };
    // const isRefreshAllowed = () => prevents?.some((el: boolean) => el === false)
    /**
     * Refresh local data by server data
     * @param userData IUserData
     */
    var refresh = function () {
        refresUserData();
        refreshBalance();
        refreshStatistic();
    };
    var refresUserData = function () {
        getUserData({})
            .then(function (response) {
            setLocalData(response);
        })
            .catch(function (error) { return console.log("updateUserData Error", error); })
            .finally(function () {
            console.log('refresUserData finally');
            // setIsLoading(false)
        });
    };
    var refreshBalance = function () {
        getBalance().then(function (res) { return setUserBalance(res); });
    };
    var refreshStatistic = function () {
        getUsersStatistics({})
            .then(function (res) { return setUserStatistic(res); });
    };
    /**
     * Send changes to remote, and save local
     * @param userData IUserData
     */
    var change = function (userData) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setUserDataAfterReg(userData).then(function () {
                        // @ts-ignore
                        setLocalData(userData);
                    }).catch(function (error) {
                        throw error;
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var changeAvatar = function (avatarData) {
        updateUserAvatar(avatarData).then(function () {
            // @ts-ignore
            refresh();
        }).catch(function (error) { return console.log("changeAvatar Error", error); });
    };
    var isPhoneOrEmailConfirmed = data.status !== "199" && data.status !== "200";
    var isAccountRestricted = !(data === null || data === void 0 ? void 0 : data.phone);
    return { data: data, userBalance: userBalance, userStatistic: userStatistic, setData: setData, setLocalData: setLocalData, refresh: refresh, change: change, changeAvatar: changeAvatar, isPhoneOrEmailConfirmed: isPhoneOrEmailConfirmed, refreshBalance: refreshBalance, isAccountRestricted: isAccountRestricted };
}
