var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { MetaDataContext } from ".";
import { useMetaData } from "@/hooks/useMetaData";
import { useLanguage } from "@/hooks/useLanguage";
export function MetaDataContextProvider(_a) {
    var children = _a.children;
    var _b = React.useState({}), metaData = _b[0], setMetaData = _b[1];
    var providerInterface = React.useMemo(function () { return ({
        metaData: metaData,
        setMetaData: setMetaData,
    }); }, [metaData]);
    return (_jsxs(MetaDataContext.Provider, __assign({ value: providerInterface }, { children: [children, _jsx(InitialLoad, {})] })));
}
var InitialLoad = React.memo(function () {
    var refreshMetaData = useMetaData().refreshMetaData;
    var language = useLanguage().language;
    React.useEffect(function () {
        refreshMetaData();
    }, [language]);
    return null;
});
