var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ProfileData } from "@/context";
import { useProfileData } from "@/hooks/useProfileData";
export function ProfileContextProvider(_a) {
    var children = _a.children;
    var _b = React.useState({}), userData = _b[0], setUserData = _b[1];
    var _c = React.useState({}), balance = _c[0], setBalance = _c[1];
    var _d = React.useState({}), statistic = _d[0], setStatistic = _d[1];
    var providerInterface = React.useMemo(function () { return ({
        data: userData, setData: setUserData,
        userBalance: balance, setUserBalance: setBalance,
        userStatistic: statistic, setUserStatistic: setStatistic
    }); }, [userData, balance, statistic]);
    return (_jsxs(ProfileData.Provider, __assign({ value: providerInterface }, { children: [children, _jsx(InitalLoad, {})] })));
}
var InitalLoad = React.memo(function () {
    var refresh = useProfileData().refresh;
    React.useEffect(function () {
        refresh();
    }, []);
    return null;
});
