import { jsx as _jsx } from "react/jsx-runtime";
import { ChallengeContextProvider } from "./ChallengeContext";
import { CreateChallengeContextProvider } from "./CreateChallengeContext";
import { ImageContextProvider } from "./ImageContext";
import { IsJudgeViewContextProvider } from "./IsJudgeViewContext";
import LoadResultContextProvider from "./LoadResultContext";
import { MetaDataContextProvider } from "./MetaDataContext";
import { MobileSidebarContexProvider } from "./MobileSidebarContext";
import { ModalContextProvider } from "./ModalContext";
import { MyChallengeJudgeSwiperContextProvider } from "./MyChallengeJudgeSwiperContext";
import { ProfileContextProvider } from "./ProfileContext";
import { SidebarContexProvider } from "./SideBarContext";
import { ThemeContextProvider } from "./ThemeContext";
import { ViewJudgeContextProvider } from "./ViewJudgeContext";
export function ContextProviders(_a) {
    var children = _a.children;
    return (_jsx(ProfileContextProvider, { children: _jsx(ThemeContextProvider, { children: _jsx(SidebarContexProvider, { children: _jsx(ImageContextProvider, { children: _jsx(ChallengeContextProvider, { children: _jsx(ModalContextProvider, { children: _jsx(MetaDataContextProvider, { children: _jsx(CreateChallengeContextProvider, { children: _jsx(MobileSidebarContexProvider, { children: _jsx(ViewJudgeContextProvider, { children: _jsx(MyChallengeJudgeSwiperContextProvider, { children: _jsx(IsJudgeViewContextProvider, { children: _jsx(LoadResultContextProvider, { children: children }) }) }) }) }) }) }) }) }) }) }) }) }));
}
