var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var IsJudgeViewContext = React.createContext({
    isJudgeClick: false,
    setIsJudgeClick: function () { },
});
export function IsJudgeViewContextProvider(_a) {
    var children = _a.children;
    var _b = React.useState(false), isJudgeClick = _b[0], setIsJudgeClick = _b[1];
    var providerInterface = React.useMemo(function () { return ({
        isJudgeClick: isJudgeClick,
        setIsJudgeClick: setIsJudgeClick
    }); }, [isJudgeClick]);
    return (_jsx(IsJudgeViewContext.Provider, __assign({ value: providerInterface }, { children: children })));
}
