import React from "react";
export function useLanguage() {
    var _a = React.useState(localStorage.getItem("YourChallengeLanguage") === "en" ? "en" : "ru"), language = _a[0], setLanguage = _a[1];
    function getLanguageSettings() {
        setLanguage(localStorage.getItem("YourChallengeLanguage") === "en" ? "en" : "ru");
    }
    function changeLanguage(languageValue) {
        localStorage.setItem("YourChallengeLanguage", languageValue);
        window.dispatchEvent(new Event('storage'));
    }
    React.useEffect(function () {
        getLanguageSettings();
        window === null || window === void 0 ? void 0 : window.addEventListener('storage', getLanguageSettings);
        return function () {
            window === null || window === void 0 ? void 0 : window.removeEventListener('storage', getLanguageSettings);
        };
    }, []);
    return {
        language: language,
        changeLanguage: changeLanguage
    };
}
