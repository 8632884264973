var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var MobileSidebarContext = React.createContext({
    openSidebar: false,
    setOpenSidebar: function () { },
});
export function MobileSidebarContexProvider(_a) {
    var children = _a.children;
    var _b = React.useState(false), openSidebar = _b[0], setOpenSidebar = _b[1];
    var providerInterface = React.useMemo(function () { return ({
        openSidebar: openSidebar,
        setOpenSidebar: setOpenSidebar
    }); }, [openSidebar]);
    return (_jsx(MobileSidebarContext.Provider, __assign({ value: providerInterface }, { children: children })));
}
