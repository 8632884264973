/** @format */
import React from 'react';
import client from '@/api/provider';
// import { useShutToken } from "./useModal";
function useAuth() {
    var _a = React.useState(false), isAuth = _a[0], setIsAuth = _a[1];
    var _b = React.useState(true), isTempToken = _b[0], setTempToken = _b[1];
    var checkAuth = function () {
        // console.log('checkAuth', localStorage.getItem('X-Token'), !!localStorage.getItem('X-Token'))
        setIsAuth(!!localStorage.getItem(client.tokenField));
    };
    var checkTempToken = function () {
        setTempToken(!!localStorage.getItem(client.tempTokenField));
    };
    var exit = function () {
        localStorage.removeItem(client.tokenField);
        window.dispatchEvent(new Event('storage'));
    };
    React.useEffect(function () {
        checkAuth();
        window === null || window === void 0 ? void 0 : window.addEventListener('storage', checkAuth);
        return function () {
            window === null || window === void 0 ? void 0 : window.removeEventListener('storage', checkAuth);
        };
    }, []);
    React.useEffect(function () {
        checkTempToken();
        window === null || window === void 0 ? void 0 : window.addEventListener('storage', checkTempToken);
        return function () {
            window === null || window === void 0 ? void 0 : window.removeEventListener('storage', checkTempToken);
        };
    }, []);
    return { isAuth: isAuth, isTempToken: isTempToken, exit: exit };
}
export default useAuth;
